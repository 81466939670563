import "../css/footer.scss"
import React from "react"
import Img from "gatsby-image"

const Footer = ({ logo }) => (
  <footer className='footer'>
    <div className='contentContainer'>
      <div className='logoContainer'>
        <Img fluid={logo} className="footerLogo" />
        <div className='copyRight'>
          © {new Date().getFullYear()}, OrderDrive Pte Ltd
        </div>
      </div>
      <p className='statement'>
        In the Making of Lean and Scalable Businesses.
      </p>
    </div>
    
  </footer>
)


export default Footer
