import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "./Footer"
import "../css/main.css"
import "../css/_partial.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      OD: file(relativePath: { eq:"images/OD.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1491, maxHeight: 842) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  return (
    <>
      <div
        style={{
          margin: `0 auto`,
          width: '100%',
        }}
      >
        <main>{children}</main>
      </div>
      <Footer
        logo={data["OD"].childImageSharp.fluid}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout